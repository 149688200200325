.vanityURLField{
  input{
    padding: 16px 14px;
    line-height: 24px;
    height: unset;
  }
  label{
    font-weight: bold;
    margin-right: 14px;
  }
  p{
    padding: 16px 14px;
    margin: 0;
  }
}
