.hero{
  padding: 40px 0;
  text-align: center;
  &-title{
    * {
      display: inline;
    }
    display: block;
    margin: 0 auto 1rem;
    max-width: 700px;
    span{
      margin-right: 6px;
    }
    img{
      display: inline;
      height: 50px;
      float: none;
    }
  }
}
