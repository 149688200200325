
.customDialog{
    .MuiDialog-container>div {
        max-width: 100%;
        min-width: 352px;
    }

    .hsDayHasData button {
        border: 1px solid transparent;
    }

    .hsDayHasData button {
        font-weight: bolder;
    }

    .MuiDateRangePickerDay-root:not(.hsDayHasData) button {
        opacity: .6;
    }

    h1.MuiTypography-h1 {
        color: rgba(0, 0, 0, 0.6);
        text-transform: uppercase;
        font-size: 1.3rem;
        margin-bottom: 15px;
    }

    h2.MuiDialogTitle-root {
        text-align: right;
    }
}
