@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&family=Karla:wght@300;400;500;600;700&family=Rubik:wght@300;400;500;600&display=swap');

html, body, #root{
  height: 100%;
}

.content{
  $footerHeight: 228px;
  $headerHeight: 80px;
  min-height: 100%;
  margin-bottom: -$footerHeight;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > div:not(.map){
    margin-top: $headerHeight;
    margin-bottom: 20px;
  }
  &:after {
    content: "";
    display: block;
  }
  footer, &:after{
    height: $footerHeight;
  }
  header + div{
    flex: 1;
  }
  a {
    color: inherit;
  }
}

.MuiContainer-root.flexCenter{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


