.map-container.mapboxgl-map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.hsMapboxPopup{
  color: black;
}

/* .hsMapboxPopup{
  background-color: white;
  color: black;
}

@media (prefers-color-scheme: dark) {
  .hsMapboxPopup{
    background-color: black;
    color: white;
  }
} */