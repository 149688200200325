header {
    position: fixed;
    display: flex;
    width: 100%;
    padding: 1rem;
    justify-content: center;
    z-index: 1;
    .logo{
        height: 40px;
        width: auto;
        img{
            display: block;
        }
    }
}
