.contentBlock{
  max-width: 900px;
  margin: 3rem auto;
  img{
    width: 100%;
  }
  table{
    th{
      font-weight: bold;
    }
    svg{
      display: inherit;
    }
  }
}
