Footer{
  overflow: hidden;
  padding: 30px 0 30px;
  text-align: center;
  img{
    width: 50px;
  }

  .socialLinks {
    display: flex;
    justify-content: center;
    width: 100%;

    li {
      margin: 0 5px;
    }
  }
}
