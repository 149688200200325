.accountPage {
    margin: 0 auto;
    width: 90%;
    max-width: 700px;
    margin-top: 2rem;
}

.light {

    .signInButtonGoogle {
        background-color: #f57f17;
        color: rgba(255, 255, 255, 1);

        &:hover {
            background-color: #f57f17;
        }   
    }

    .signInButtonFacebook {
        background-color: #f57f17;
        color: rgba(255, 255, 255, 1);

        &:hover {
            background-color: #f57f17;
        }
    }

    .signInButtonTwitter {
        background-color: #f57f17;
    }
}

.dark {
    .signInButtonGoogle {
        background-color: #f57f17;
        color: rgba(255, 255, 255, 1);

        &:hover {
            background-color: #f57f17;
        }
    }

    .signInButtonFacebook {
        background-color: #f57f17;
        color: rgba(255, 255, 255, 1);

        &:hover {
            background-color: #f57f17;
        }
    }

    .signInButtonTwitter {
        background-color: #f57f17;
    }
}

div.signInButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button.signInButton {
        padding: 8px;
        justify-content: start;
        margin-bottom: 1rem;
        width: 220px;
        max-width: 90%;

        img {
            width: 18px;
            height: auto;
            margin-right: 24px;
        }

        .socialMediaLogo {
            width: 18px;
            height: auto;
            margin-right: 24px;
        }
    }
}

*:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

div.MuiInputBase-inputAdornedStart {
    padding-left: 12px;
}