.video{
  position: relative;
  height: 35vh;
  width: 100%;
  border-left:0;
  border-right:0;
  margin: 0 auto;
  overflow: hidden;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    bottom:0;
    right: 0;
    left:0;
    display: block;
    height: 100%;
    width: 100%;
    background: inherit;
    opacity: .5;
  }
  video{
    position: absolute;
    top:50%;
    transform: translate(-50%, -50%);
    right:0;
    bottom:0;
    left:50%;
    width: 100%;
  }
}
